import {AComponent} from "../abstract/AComponent";
import {setTransform} from "../utils/setTransform";

export class Quiz extends AComponent {
    initialize(params) {
        super.initialize(params);

        this.$$questions = ".question";
        this.$explicationContainer = ".explication-container";
        this.$$explications = ".explication";
        this.$$legals = ".legals";
        this.$$options = ".options";
        this.$leftPart = ".left-part";
        this.$rightPart = ".right-part";
        this.$score = ".score";

        this.currentIdQuestion = 0;
        this.userGoodAnswers = 0;
        this.questionsNumber = 0;

        this.events = {
            'click .option:not(.restart-btn)': 'getAnswer',
            'click .btn.-next': 'nextQuestion',
            'click .restart-btn': 'restartQuiz',
            'click .valid-btn': 'showForm',
        }
    }

    bind() {
        super.bind();
        this.handleIntersection = this.handleIntersection.bind(this)
        const observer = new IntersectionObserver(this.handleIntersection, {
            threshold: .2,
        });
        observer.observe(this.$el);

        this.questionsNumber = this.$$questions.length - 1;
    }

    resize(wW, wH) {
        super.resize(wW, wH);
        this.setQuizMarginLeft();
    }

    handleIntersection(entries, observer) {
        entries.forEach(entry => {
            const targetElement = entry.target;

            if (entry.isIntersecting) {
                targetElement.classList.add('-appear');
            }
        });
    }

    showForm() {
        this.$el.classList.add('-form-active')
    }

    setQuizMarginLeft() {
        setTransform(this.$leftPart, `translate3d(${((this.$el.offsetWidth - this.$leftPart.offsetWidth) / 2) / 10 + "rem"}, 0, 0)`);
    }

    getAnswer(e) {
        e.preventDefault();
        let currentAnswer = e.target;
        let value = parseInt(currentAnswer.getAttribute('data-value'));
        if (!value) {
            value = 0;
        }
        this.currentIdQuestion = this.getIdCurrentQuestion();

        if (this.currentIdQuestion === 0) {
            this.initializeExplicationCard();
        }

        this.showExplication(this.currentIdQuestion);
        this.highlightAnswers(currentAnswer, this.currentIdQuestion);
    }

    getIdCurrentQuestion() {
        let currentId = 0;
        this.$$questions.forEach((question, index) => {
            if (question.classList.contains('-show')) {
                currentId = index;
            }
        });
        return currentId;
    }

    getCurrentOptions(currentIdQuestion) {
        return Array.from(this.$$options[currentIdQuestion].querySelectorAll('.option'));
    }

    initializeExplicationCard() {
        setTransform(this.$leftPart, `translate3d(0, 0, 0)`);
        this.$rightPart.classList.add('-show');
    }

    showExplication(currentIdQuestion) {
        this.$$explications.forEach((explication, index) => {
            if (index === currentIdQuestion) {
                explication.classList.add('-show');
            }
        });
        this.$$legals.forEach((legal, index) => {
            if (index === currentIdQuestion) {
                legal.classList.add('-show');
            }
        });
        this.$explicationContainer.classList.add('-show');
    }

    highlightAnswers(currentAnswer, currentIdQuestion) {
        const options = this.getCurrentOptions(currentIdQuestion);
        options.forEach((option, index) => {
            option.classList.add('-locked');
            if (option.getAttribute('data-value') === '1') {
                option.parentElement.classList.add('-right');
            }
        });

        if (!currentAnswer.getAttribute('data-value')) {
            currentAnswer.parentElement.classList.add('-wrong');
            this.$el.classList.add('-has-error')
        } else {
            this.userGoodAnswers++
        }
    }

    nextQuestion(currentIdQuestion) {
        this.$el.classList.remove('-has-error')
        let nextIdQuestion = this.currentIdQuestion + 1;
        //this.$el.classList.remove('-has-error')
        this.$$questions.forEach((question, index) => {
            if (!(index >= this.questionsNumber)) {
                if (index === nextIdQuestion) {
                    question.classList.add('-show');
                    this.$$options[index].classList.add('-show');
                } else {
                    question.classList.remove('-show');
                    this.$$options[index].classList.remove('-show');
                }

                this.$explicationContainer.classList.remove('-show');
                this.$$legals[index].classList.remove('-show');
                setTimeout(() => {
                    this.$$explications[index].classList.remove('-show');
                }, 300);
            }
        });

        if (nextIdQuestion === this.questionsNumber) {
            this.endOfQuiz();
        }
    }

    endOfQuiz() {
        this.$el.classList.remove('-has-error')
        this.$score.innerHTML = this.userGoodAnswers + "/" + this.questionsNumber;
        this.$rightPart.classList.remove('-show');
        this.$$questions[this.questionsNumber].classList.add('-show', '-end');

        setTimeout(() => {
            this.setQuizMarginLeft();
        }, 200);

        if (this.userGoodAnswers !== (this.questionsNumber)) {
        } else {
            this.$$options[this.questionsNumber].classList.add("-show");
            this.$el.querySelectorAll('.-valid').forEach($el => {
                $el.classList.remove('-hide')
            })
            this.$el.querySelector('.-restart').classList.add('-hide');
            this.$el.querySelector('.wording').classList.add('-hide');
        }

        this.quizLoosed();
    }

    quizLoosed() {
        this.$$options[this.questionsNumber].classList.add("-show");

        this.$$options.forEach((option) => {
            Array.from(option.children).forEach((op) => {
                op.classList.remove('-right', '-wrong');
                op.querySelector('.option').classList.remove('-locked');
            });
        });
    }

    restartQuiz() {
        this.userGoodAnswers = 0;
        this.$$options[this.questionsNumber].classList.remove("-show");
        this.$$questions[this.questionsNumber].classList.remove('-show', '-end');
        this.$$questions[0].classList.add('-show');
        this.$$options[0].classList.add('-show');
    }
}
