
import {Popin} from "./classes/components/Popin";
import {Form} from "./classes/components/Form";
import {Quiz} from "./classes/components/Quiz";
import {Starquiz} from "./classes/components/Starquiz";

F.components = {
    Popin,
    Form,
    Quiz,
    Starquiz,
};
