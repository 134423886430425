import {AComponent} from "../abstract/AComponent";

export class Starquiz extends AComponent {
    initialize(params) {
        super.initialize(params);

        this.$$questions = ".frame-question";
        this.$$frameContentContainer = ".frame-content-container";
        this.$$options = ".option-container";
        this.$$lineLink = ".line-link";
        this.$$explicationContent = ".explication-corner-container";
        this.$frameRestart = ".frame-end";
        this.$restartContainer = ".restart-container";
        this.$validContainer = ".valid-container";
        this.$score = ".score";
        this.$$optionBtns = ".option";
        this.$mousePos = ".mouse-pos";

        this.currentIdQuestion = 0;
        this.userGoodAnswers = 0;
        this.questionsNumber = 0;
        this.initialWrapperHeight = 0;
        this.toggleAnim = 0;

        this.spaceBgs = document.querySelectorAll('.header-bg.-space .bg-img');

        this.events = {
            'click .btn.-start': 'startQuiz',
            'click .option': 'getAnswer',
            'click .btn.-next': 'nextQuestion',
            'click .btn.-restart': 'restartQuiz',
            'click .btn.-valid': 'showForm',
        }
    }

    bind() {
        super.bind();

        this.questionsNumber = this.$$questions.length - 1;

        //this.startQuiz();
        //this.showExplication(5);

        this.setFakeData()
    }

    startQuiz() {
        this.$$frameContentContainer.forEach((frameContentContainer, index) => {
            if (frameContentContainer.classList.contains('frame-start')) {
                frameContentContainer.classList.add('-prev');
            }

        });
        setTimeout(() => {
            this.$$questions[0].classList.remove('-next');
        }, 400);

        this.setStarSpeed();
    }

    getIdCurrentQuestion() {
        let currentId = 0;
        this.$$questions.forEach((question, index) => {
            if (!question.classList.contains('-prev') && !question.classList.contains('-next')) {
                currentId = index;
            }
        });
        return currentId;
    }

    getAnswer(e) {
        e.preventDefault();
        let currentAnswer = e.target;
        this.currentIdQuestion = this.getIdCurrentQuestion();

        this.showExplication(this.currentIdQuestion);
        this.highlightAnswers(currentAnswer, this.currentIdQuestion);
        this.initStarSpeed();
    }

    showExplication(currentIdQuestion) {
        this.$$frameContentContainer[currentIdQuestion + 1].classList.add('-show-explication');
        this.checkRepositionExplication(currentIdQuestion);
        this.setWrapperHeight(true, currentIdQuestion);

        setTimeout(() => {
            this.$$lineLink[currentIdQuestion].classList.add('-show');
        }, 400)

        if (F.isMobile()) {
            this.$$explicationContent[currentIdQuestion].scrollIntoView({behavior: "smooth", block: "center"});
        }
    }

    highlightAnswers(currentAnswer, currentIdQuestion) {
        const options = this.getCurrentOptions(currentIdQuestion);
        options.forEach((option, index) => {
            option.classList.add('-locked');
            if (option.getAttribute('data-value') === '1') {
                option.classList.add('-right');
                this.$$lineLink[currentIdQuestion].classList.add('-a-' + (index + 1));
            } else {
                option.classList.add('-wrong');
            }
        });

        if (currentAnswer.getAttribute('data-value')) {
            this.userGoodAnswers++;
        } else {
            this.$$frameContentContainer[currentIdQuestion + 1].classList.add('-has-error');
            this.$el.classList.add('-has-error');
        }
    }

    getCurrentOptions(currentIdQuestion) {
        return Array.from(this.$$options[currentIdQuestion].querySelectorAll('.option'));
    }

    nextQuestion() {
        this.$el.classList.remove('-has-error');
        this.$$frameContentContainer[this.currentIdQuestion + 1].classList.add('-prev');
        this.setWrapperHeight(false, this.currentIdQuestion);
        this.$$lineLink[this.currentIdQuestion].classList.remove('-show');

        setTimeout(() => {
            this.$$frameContentContainer[this.currentIdQuestion + 2].classList.remove('-next');
        }, 400);

        if (this.currentIdQuestion >= this.questionsNumber) {
            this.endOfQuiz();
        }

        if (F.isMobile()) {
            this.$$questions[this.currentIdQuestion].scrollIntoView({behavior: "smooth", block: "center"});
        }

        this.setStarSpeed();
    }

    checkRepositionExplication(currentIdQuestion) {
        let optionContainerHeight = this.$$options[currentIdQuestion].offsetHeight;
        let explicationHeight = this.$$explicationContent[currentIdQuestion].offsetHeight;

        if (optionContainerHeight > explicationHeight) {
            let margin = (optionContainerHeight - explicationHeight) / 2;
            this.$$explicationContent[currentIdQuestion].style.marginBottom = (margin / 10) + 'rem';
        }
    }

    endOfQuiz() {
        //this.userGoodAnswers = 6;
        this.$score.innerHTML = 'Votre score : ' + this.userGoodAnswers + '/' + (this.questionsNumber + 1);
        if (this.questionsNumber + 1 === this.userGoodAnswers) {
            this.$validContainer.classList.add('-show');
        } else {
            this.$restartContainer.classList.add('-show');
        }
    }

    restartQuiz() {
        this.currentIdQuestion = 0;
        this.userGoodAnswers = 0;
        setTimeout(() => {
            this.$restartContainer.classList.remove('-show');
        }, 300);

        this.reverseStarSpeed();

        this.$$optionBtns.forEach((optionBtn) => {
            optionBtn.classList.remove('-locked', '-right', '-wrong');
        });

        this.$$frameContentContainer.forEach((frameContentContainer, index) => {
            frameContentContainer.classList.remove('-show-explication', '-has-error');
            if (index < this.$$questions.length) {
                this.$$questions[index].classList.remove('-prev');
                this.$$questions[index].classList.add('-next');
            }
        });

        this.$frameRestart.classList.add('-prev');

        setTimeout(() => {
            this.$$questions[0].classList.remove('-next');
            this.$frameRestart.classList.add('-next');
            this.$frameRestart.classList.remove('-prev');
        }, 400);
    }

    showForm() {
        document.querySelector('.vos-informations').classList.add('-show');
    }

    setWrapperHeight(bool, currentIdQuestion) {
        if (F.isMobile()) {
            let gradientMask = document.querySelector('.gradient-mask')
            if (bool) {
                this.initialWrapperHeight = this.$el.offsetHeight / 10 + 'rem';
                this.$el.style.height = this.initialWrapperHeight;
                let newWrapperHeight = this.$$frameContentContainer[currentIdQuestion + 1].querySelector('.frame-content').offsetHeight + this.$$explicationContent[currentIdQuestion].offsetHeight + 100 + 46 + 100;
                this.$el.style.height = newWrapperHeight / 10 + 'rem';

                let wrapperQuizHeight = document.querySelector('.wrapper--starquiz').offsetHeight;
                gradientMask.style.marginBottom = (newWrapperHeight - wrapperQuizHeight) / 10 + 'rem';
            } else {
                this.$el.style.height = this.initialWrapperHeight;
                gradientMask.style.marginBottom = '';
            }
        }
    }

    setFakeData() {
        if (!F.isMobile()) {
            let mousePos = {x: undefined, y: undefined};
            window.addEventListener('mousemove', (event) => {
                mousePos = {x: event.clientX, y: event.clientY};
                this.$mousePos.innerHTML = this.substringData(mousePos.x + mousePos.y) + '° ' + this.substringData(mousePos.x) + '\' ' + this.substringData(mousePos.y) +
                        '" <br> / -' + mousePos.x.toString().slice(-1) + '° ' + this.substringData(mousePos.x / 2) + '\' ' + this.substringData(mousePos.y / 2) + '" ' + this.substringData(mousePos.x * 2) + '° / ' + this.substringData(mousePos.y * 2) + '°';
            });
        }
    }

    substringData(data) {
        return data.toString().substring(0, 3);
    }

    initStarSpeed() {
        this.spaceBgs.forEach((spaceBg) => {
            if (spaceBg.classList.contains('-front')) {
                spaceBg.classList.add('-back');
                spaceBg.classList.remove('-front');
            }
        });
    }

    setStarSpeed() {
        this.spaceBgs.forEach((spaceBg) => {
            if (spaceBg.classList.contains('-back')) {
                spaceBg.classList.remove('-back');
            } else {
                spaceBg.classList.add('-front');
            }
        });
    }

    reverseStarSpeed() {
        this.spaceBgs.forEach((spaceBg) => {
            if (spaceBg.classList.contains('-front')) {
                spaceBg.classList.remove('-front');
            } else {
                spaceBg.classList.add('-back');
            }
        });
    }
}