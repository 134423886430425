import {AComponent} from "../abstract/AComponent";
import {ajax} from "../utils/ajax";

export class Form extends AComponent {
    initialize(params) {
        this.$validation = '.validation';
        this.$already = '.already';
        this.$formulaire = '.formulaire';
        this.$error = '.error';
        this.data = [];
        super.initialize(params);

        this.events = {
            'submit': 'onSubmit'
        }
    }


    onSubmit(e) {
        e.preventDefault();

        this.$el.classList.add('loading');
        this.data['task'] = 'send_form'

        this.$el.querySelectorAll('.field-input').forEach($field => {
            this.data[$field.name] = $field.type === 'checkbox' ? $field.checked ? 'Oui' : '' : $field.value
        });

        this.$error.innerHTML = '';

        ajax({
            url: F.CONFIG.PATH_URL + 'api.php',
            data: this.data,
            success: data => {
                if (data.error === 0) {
                    /*this.$formulaire.classList.remove('show');
                    this.$validation.classList.add('show');
                    this.$el.querySelectorAll('.field').forEach($field => {
                        $field.value = '';
                    });*/
                    this.$formulaire.reset();

                    F.Popin.show(
                        'Merci pour votre participation !',
                        'Celle-ci a bien été enregistrée. <br>La date du tirage au sort pour tenter de remporter vos places pour le STARQUIZ aura lieu le <strong>08/04/2024</strong>.\n'
                    );

                    tc_vars.screen_name = "starquiz/confirmation-inscription";
                } else {
                    if (data.msg === 'already') {
                        //this.$formulaire.classList.remove('show');
                        //this.$already.classList.add('show');
                        this.$formulaire.reset();

                        F.Popin.show(
                            'Vous avez déjà participé !',
                            'Votre participation au jeu concours a déjà été reçue. <br>Le tirage au sort pour tenter de remporter vos places pour le STARQUIZ aura lieu le <strong>08/04/2024</strong>.'
                        );

                        tc_vars.screen_name = "starquiz/deja-inscrit";
                    } else if (data.msg === 'id') {
                        this.$error.innerHTML = "L'identifiant EDF est incorrect.";
                        tc_vars.screen_name = "starquiz/erreur";
                    } else {
                        this.$error.innerHTML = data.msg;

                        tc_vars.screen_name = "starquiz/erreur";
                    }
                }

                tC.container.reload();
                tC.event.pageview(this, {});

                this.$el.classList.remove('loading');
            },
            error: e => {
                //console.warn(e);
                //this.$error.innerHTML = e.msg;
                //this.$error.innerHTML = e;

                tc_vars.screen_name = "starquiz/erreur";
                tC.container.reload();
                tC.event.pageview(this, {});

                this.$el.classList.remove('loading');
            }
        });
    }


}
