export class Cookies {
    static get(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return false;
    }
    static deleteAll() {
        let cookies = document.cookie.split("; ");
        for (let c = 0; c < cookies.length; c++) {
            let d = window.location.hostname.split(".");
            while (d.length > 0) {
                let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                let p = location.pathname.split('/');
                document.cookie = cookieBase + '/';
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join('/');
                    p.pop();
                };
                d.shift();
            }
        }
    }
    static set(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    static acceptCookie() {
        window.F.cookieAllowed = true;
        window.ga && window.ga('set', 'anonymizeIp', false);
        Cookies.set('accept-cookie', 'yes', 30 * 12);
    }
    static defaultAcceptCookie() {
        window.F.cookieAllowed = true;
        Cookies.set('accept-cookie', 'default', 30 * 2);
    }
    static declineCookie() {
        Cookies.deleteAll();
        window.F.cookieAllowed = false;
        Cookies.set('accept-cookie', 'no', 2);

        if(F.CONFIG.GA_UA) {
            console.warn('F.CONFIG.GA_UA undefined');
            return;
        }
        window['ga-disable-'+F.CONFIG.GA_UA] = true;
    }
}
