import 'whatwg-fetch'
import 'promise-polyfill/src/polyfill';
import { concatGetParamToUrl } from "./concatGetParamToUrl";

const $SVG = document.querySelector('#svg-use');
export function ajax(params) {
    function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            let error = new Error(response.statusText);
            error.response = response;
            throw error
        }
    }

    function parseJSON(response) {
        if (response.headers.get('Content-Type').indexOf('json') !== -1) {
            return response.json()
        } else {
            return response.text().then(text => {
                return JSON.parse(text.replace('<script type="text/javascript">window.location.reload();</script>', ''))
            })
        }
    }

    params.datas = params.datas || params.data;
    let formData = null;
    if(!(params.datas instanceof FormData)) {
        formData = new FormData();
        if (params.datas) {
            for (let key in params.datas) {
                if (params.datas.hasOwnProperty(key)) {
                    formData.append(key, params.datas[key]);
                }
            }
        }
    } else {
        formData = params.datas;
    }

    let fetchParams = {method: params.method || 'POST', credentials: 'include'};
    if(fetchParams.method === 'POST') {
        fetchParams.body = formData;
    } else {
        params.url = concatGetParamToUrl(params.url, new URLSearchParams(formData).toString())
    }

    //body: formData
    fetch(params.url, fetchParams)
        .then(checkStatus)
        .then(parseJSON)
        .then(data => {
            params.success && params.success(data);
        }).catch(error => {
        console.warn('request failed', error);
        params.error && params.error(error);
    });
}
