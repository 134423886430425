import './import';
import './classes/utils/polyfill.js';
import 'promise-polyfill/src/polyfill'
import {Render} from "./classes/utils/Render";
import {Resizable} from "./classes/utils/Resizable";
import {AComponent} from "./classes/abstract/AComponent";
import {ucfirst} from "./classes/utils/ucfirst";
import {delegateEventListener} from "./vendor/event-delegation";
import {Cookies} from "./classes/utils/Cookies";


if (!window.console || !window.console.log) {
    window.console = {
        log: function () {
        }, warn: function () {
        }
    }
}

/**
 * COUCOU C NOUS
 */
console.big = function () {
    console.log('%c   ' + Array.from(arguments).join(',') + '   ', 'background: #000000; color: #ffffff');
};

console.log('%c FCINQ ', 'background: #000; color: #fff; font:bold 40px/40px impact, sans-serif;');


/**
 * écouteur scroll/resize/load
 * + RAF
 */
F._.wW = window.innerWidth;
F._.wH = window.innerHeight;
F._.scrollTop = window.pageYOffset;

F.Render = new Render();
F.Resizable = new Resizable();

let resizableElmts = [],
    scrollableElmts = [],
    lastScrollTop = 0,
    scrollTop = 0;
window.addEventListener('scroll', () => {
    scrollTop = window.pageYOffset;
    F._.scrollTop = scrollTop;
});

window.addEventListener('resize', () => {
    F._.wW = window.innerWidth;
    F._.wH = window.innerHeight;
    for (let i = 0, e = null; e = resizableElmts[i]; i++) {
        e.resize && e.resize(F._.wW, F._.wH);
    }
    F.Resizable.resize();
});

window.addEventListener('load', () => {
    F.Resizable.resize();
});

requestAnimationFrame(function tick() {
    F.Render.update();
    if (lastScrollTop !== scrollTop) {
        F._.scrollTop = scrollTop;
        for (let i = 0, e = null; e = scrollableElmts[i]; i++) {
            e.scroll && e.scroll(scrollTop, lastScrollTop);
        }
        lastScrollTop = scrollTop;
    }

    requestAnimationFrame(tick);
});

/**
 * instancie un acomponent sur le body pour créer tout les components présents dans le DOM
 */
let bodyView = new AComponent({el: document.body});
bodyView.render();
bodyView.bind();
bodyView.willAppear();
bodyView.didAppear();
scrollableElmts.push(bodyView);

for (let key in bodyView.$refs) {
    if (bodyView.$refs.hasOwnProperty(key)) {
        F[ucfirst(key)] = bodyView.$refs[key];
    }
}

if (!Cookies.get('has_seen_rules')) {
    const rules = [
        'Jeu concours réservé aux clients EDF.',
        'Vous devez faire un sans faute pour être éligible au tirage au sort. Pas de panique ! Vous pouvez rejouer autant de fois que vous le souhaitez.',
        'Mais attention, seul votre dernier score sera conservé et vous ne pourrez valider qu\'un seul formulaire de participation au tirage au sort.',
        'Pour participer au tirage au sort, munissez-vous de votre numéro client EDF (à retrouver dans l\'email que vous avez reçu ou dans votre espace client, rubrique Factures et Paiements, en haut à gauche de l\'une de vos factures EDF).'
    ]

    const list = document.createElement('ul');
    rules.forEach(rule => {
        const item = document.createElement('li');
        item.textContent = rule;
        list.appendChild(item);
    });

    F.Popin.show('Règles du jeu', list.innerHTML, true, 'rules', ['-rules'])
}


delegateEventListener(document.body, 'click', '.trigger-js', e => {
    e.preventDefault();

    if (e.currentTarget.classList.contains('open-popin')) {
        e.stopPropagation();
        let content = null;
        if (e.currentTarget.getAttribute('data-content')[0] === '#') {
            content = document.querySelector(e.currentTarget.getAttribute('data-content')).innerHTML;
        } else {
            content = e.currentTarget.parentNode.querySelector(e.currentTarget.getAttribute('data-content')).innerHTML;
        }
        F.Popin.show(e.currentTarget.getAttribute('data-title'), content, true, e.currentTarget.getAttribute('data-type'));
        return;
    }

    if (e.currentTarget.getAttribute('data-trigger-component') && e.currentTarget.getAttribute('data-trigger-action')) {
        F[e.currentTarget.getAttribute('data-trigger-component')][e.currentTarget.getAttribute('data-trigger-action')]();
    }

});
