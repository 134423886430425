export function concatGetParamToUrl(url, get) {
    if(url.indexOf(get) !== -1) {
        return url;
    }
    if(url.indexOf('?') > -1) {
        url+= '&';
    } else {
        url+= '?';
    }
    return url+get;
}